import request from '@/../utils/request'
const api_name = '/iotechserver/subscribeManage'

export default {
    getSubscribeManageList(current, limit) {
        return request({
            url: `${api_name}/pageSubscribeManage/${current}/${limit}`,
            method: 'get',
        })
    },
    addSubscribeManageUrl(subscribeManageInfo) {
        return request({
            url: `${api_name}/addSubscribeManageUrl`,
            method: 'post',
            data: subscribeManageInfo
        })
    },
    deleteSubscribeManageUrl(subscribeManageInfo) {
        return request({
            url: `${api_name}/deleteSubscribeManageUrl`,
            method: 'delete',
            data: subscribeManageInfo
        })
    },
}