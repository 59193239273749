<template>
  <div id="app" class="url" style="padding: 15px">
    <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="第三方订阅管理">
        </el-page-header>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-button
        style="margin-left: 10px"
        type="success"
        @click="dialogFormVisible = true"
        >添加</el-button
      >
    </el-row>

    <el-dialog title="添加" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="订阅地址" :label-width="formLabelWidth">
          <el-input v-model="form.url" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUrl">确 定</el-button>
      </div>
    </el-dialog>

    <el-table :data="subscribeManageList" border style="width: 100%">
      <el-table-column
        fixed
        type="index"
        label="编号"
        align="center"
        width="80"
      ></el-table-column>

      <el-table-column prop="url" label="订阅地址"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            @click="deleteSubscribeManageUrl(scope.row)"
            type="danger"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="current"
      :page-size="limit"
      :total="total"
      layout="total, prev, pager, next, jumper"
      style="padding-top: 10px;  text-align: center"
      @current-change="getUrlList"
    ></el-pagination>
  </div>
</template>

<script>
import subscribeManage from "../../api/manageApi/SubscribeManage";
import BaseData from "@/assets/config/BaseData.js";
export default {
  data() {
    return {
      //分页相关数据
      current: 1, //当前页
      limit: 5, //每页显示条数
      total: 0, //数据总数

      //页面数据
      subscribeManageList: [], //CRT集合

      form: {
        url: "",
      },
      formLabelWidth: "120px",
      dialogFormVisible: false,
      subscribeManageInfo: {
        url: "",
        ciid: "0",
        deviceTypeId: "0",
      },
    };
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      this.getSubscribeManageList();
    },
    //返回上一级
    goBack() {
      window.history.back();
    },
    goSelect() {
      this.$router.push("/Select");
    },
    getUrlList() {
      this.getSubscribeManageList();
    },
    getSubscribeManageList() {
      subscribeManage
        .getSubscribeManageList(this.current, this.limit)
        .then((res) => {
          console.log(res);
          this.subscribeManageList = res.data.data.list;
          this.total = res.data.data.total;
        });
    },
    addUrl() {
      if (this.form.url != null && this.form.url != "") {
        this.subscribeManageInfo.url = this.form.url;
        subscribeManage
          .addSubscribeManageUrl(this.subscribeManageInfo)
          .then((res) => {
            this.dialogFormVisible = false;
            this.getSubscribeManageList();
          });
      } else {
        alert("请添加url");
      }
    },
    deleteSubscribeManageUrl(row) {
      this.$confirm("此操作将永久删除此信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        subscribeManage
          .deleteSubscribeManageUrl({id:row.id, url:row.url})
          .then((res) => {
            //提示信息
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });
            //回到页面并刷新
            this.getSubscribeManageList();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.url .el-row {
  margin-bottom: 20px;
}

.url .hide .el-upload--picture-card {
  display: none;
}

/deep/ .el-page-header__content {
  color: white;
}
</style>